<template>
  <CHeader
    v-if="mainMenu"
    id="header-nav"
    class="header-rx-container"
    fixed
    with-subheader
    dark
  >
    <div class="d-flex">
      <CHeaderNav>
        <template v-if="mainMenu.isSurvey">
          <CHeaderNavItem class="px-1" @click.native="removeSurvey">
            <CHeaderNavLink :to="mainMenu.link">
              {{ mainMenu.title.toUpperCase() }}
            </CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem>
            <hr
              style="
                -webkit-transform: rotate(90deg);
                width: 33.5px;
                color: #ffffff;
                border-color: #ffffff;
                margin-left: -15px;
              "
            />
          </CHeaderNavItem>
        </template>
      </CHeaderNav>
      <CHeaderNav class="mr-auto header-rx-nav">
        <CHeaderNavItem
          v-for="(menu, index) in mainMenu.childs"
          :key="index"
          class="px-1"
        >
          <CHeaderNavLink
            v-if="checkByRole(mainMenu, menu)"
            :disabled="checkDisabled(mainMenu, isNotSelectedSurvey)"
            :to="menu.link"
            style="white-space: nowrap;"
          >
            {{ menu.label }}
          </CHeaderNavLink>
        </CHeaderNavItem>
        <template v-if="mainMenu.isSurvey">
          <CHeaderNavItem
            v-if="isShowPublished"
            class="px-1"
            @click.native="publishedSurvey"
          >
            <CHeaderNavLink
              :disabled="isNotSelectedSurvey"
              class="publish-button"
            >
              <span v-if="!isSurveyEditing" style="color: black"> PUBLISH </span>
              <span v-if="isSurveyEditing" style="color: black"> SAVE & PUBLISH </span>
            </CHeaderNavLink>
          </CHeaderNavItem>
        </template>
      </CHeaderNav>
    </div>

    <b-navbar-nav class="ml-auto nav-right">
      <b-nav-item-dropdown right class="avatar-btn">
        <!-- Using 'button-content' slot -->
        <template #button-content>
          <span id="header__avatar">
            <b-icon icon="person-circle"></b-icon>
          </span>
        </template>
        <b-dropdown-item href="#" @click.native="goDashboard">
          DOCUMENT LIBRARY
        </b-dropdown-item>
        <b-dropdown-item :active="mainMenu.id === 'survey'" href="/survey" :disabled="!isAccessToLensSelector">
          LENS SELECTOR
        </b-dropdown-item>
        <b-dropdown-item :active="mainMenu.id === 'admin'" href="/admin">
          ADMIN
        </b-dropdown-item>
        <b-dropdown-item :id="'logout-button'" href="#" @click.native="logout">
          LOGOUT
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </CHeader>
</template>

<script>
import { MENU_LIST } from "../constants/HeaderMenus";
import { DOCUMENT_PATH } from '../constants/Paths';
import DocumentService from "../service/DocumentService";
import SurveyService from "../service/SurveyService";

export default {
  name: "TheHeader",
  data() {
    return {
      mainMenu: MENU_LIST[0],
    };
  },
  computed: {
    isNotSelectedSurvey() {
      const survey = this.$store.state.survey;
      return survey ? survey.id < 1 : true;
    },
    isShowPublished() {
      return this.$store.state.survey.isSurveyEditing || this.$store.state.survey.id && !this.$store.state.survey.published;
    },
    isSurveyEditing() {
      return this.$store.state.survey.isSurveyEditing
    },
    countries() {
      return this.$store.state.common.countries;
    },
    languages() {
      return this.$store.state.common.languages;
    },
    currentRoutePath() {
      return this.$route.path;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isGlobal() {
      const user = this.currentUser;
      return user && user.isGlobal;
    },
    docServerRedirect() {
      return DocumentService.getRedirectUrl(this.currentUser);
    },
    isAccessToLensSelector() {
      return this.$store.getters["auth/canAccessLenSelector"];
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$store.state.common.theHeaderHeight = this.$el.offsetHeight
    })
    this.mainMenu = MENU_LIST.find((x) =>
      this.currentRoutePath.includes(x.link)
    );
    if (!this.mainMenu) {
      this.mainMenu = MENU_LIST[0];
    }
  },
  methods: {
    goDashboard() {
      location.href = this.docServerRedirect;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$store.commit("survey/remove");
      // DocumentService.logout().then(console.log);
      window.location.href = `${DOCUMENT_PATH}/logout`
    },
    activeMenu(menu) {
      this.mainMenu = MENU_LIST.find((x) => x.id == menu);
    },
    checkDisabled(mainMenu, isNotSelectedSurvey) {
      if (!mainMenu || !mainMenu.isSurvey) return false;
      return isNotSelectedSurvey;
    },
    checkByRole(mainMenu, menu) {
      // Hide menu by role
      if (
        !mainMenu ||
        mainMenu.isSurvey ||
        !menu.roles ||
        !this.$store.state.auth.user
      )
        return true;

      return menu.roles.includes(this.$store.state.auth.user.role);
    },
    removeSurvey() {
      this.$store.commit("survey/changeSurveyFileItem", null);
      this.$store.commit("survey/remove");
    },
    publishedSurvey() {
      if (this.isSurveyEditing) {
        this.$store.state.survey.editingScreenSaveFunc(this.doPublishedSurvey);
      } else {
        this.doPublishedSurvey()
      }
    },
    doPublishedSurvey() {
      let loader = this.$loading.show();
      SurveyService.publish(this.$store.state.survey.id)
        .then(({ data }) => {
          this.$store.commit("survey/save", {
            id: data.id,
            parentId: data.parentId,
            country: {
              code: data.countryCode,
              name: this.countries[data.countryCode],
            },
            language: {
              code: data.languageCode,
              name: this.languages[data.languageCode],
            },
            published: data.published,
          });
          loader.hide();
          this.$router.go();
        })
        .catch((err) => {
          console.log(err);
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.header-rx-container {
  background: #4b4b4b;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
  height: 65px;
}

.header-rx-nav {
  overflow-y: scroll;
}
.header-rx-nav::-webkit-scrollbar {
    display: none;
}

@media (min-width: 1440px) {
  .header-rx-container {
    padding-left: 6%;
    padding-right: 6%;
  }
  .header-rx-nav {
    max-width: calc(88vw - 100.2px - 58.71px - 2rem);
  }
}

@media (max-width: 1439px) {
  .header-rx-container {
    padding-left: 3%;
    padding-right: 3%;
  }
  .header-rx-nav {
    max-width: calc(94vw - 100.2px - 58.71px - 33.5px);
  }
}

.c-header-nav-link {
  color: #ffffff !important;
  font-size: 14px;
}

.c-header-nav-link:hover {
  height: 34px;
  background: #c4c4c4;
  border-radius: 2px;
}

.header-nav-right {
  padding-right: 6%;
}

.router-link-exact-active,
.router-link-active {
  height: 34px;
  background: white;
  color: black !important;
  border-radius: 2px;
}

.c-header-nav-link.disabled:hover {
  background: none;
}

.c-header-nav-link.disabled {
  color: #ffffff80 !important;
}

.publish-button {
  background-color: rgba(253, 218, 27, 1);
  height: 34px;
  border-radius: 4px;
}

.publish-button:hover {
  background-color: rgba(253, 218, 27, 1);
  height: 34px;
  border-radius: 4px;
}

.image-button:hover {
  background-color: unset;
  height: auto;
  border-radius: unset;
}

.btn-logout-custom {
  border: 1px solid #ffffff;
  height: 34px;
  border-radius: 2px;
  padding: 10px 20px;
}

.dropdown-item {
  font-weight: bold;
  color: black;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #c4c4c4;
}

#header__avatar {
  font-size: 2rem;
  color: white;
}
</style>