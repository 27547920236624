<template>
  <div class="c-app">
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <div class="c-container">
            <router-view :key="$route.path" />
          </div>
        </main>
      </div>
    </CWrapper>
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="isShowError && expiration">
      <transition name="modal">
        <div class="modal-expire-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      Your session is expiring<br>
                      We are redirecting you to the login page in {{ getMessage }} sec
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="logout()"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
/* eslint-disable no-console */
import $ from 'jquery';
import { mapActions, mapGetters } from "vuex";
import Constants from '../common/Constants';
import LocalStoreService from "../service/LocalStoreService";
import TheHeader from "./TheHeader";

export default {
  name: "TheContainer",
  components: {
    TheHeader,
  },
  data() {
    return {
      expiration: false,
    }
  },
  computed: {
    ...mapGetters([
        'isShowError',
        'getMessage',
    ]),
  },
  created() {
    this.checkExpiration();
    $(window).on("click", () => this.checkExpiration());
    this.$router.currentRoute.path === "/" &&
      this.$router.push("/survey").catch(() => {});
    
  },
  methods: {
    ...mapActions([
        'updateMessage',
        'updateShowError',
    ]),
    logout() {
      this.$store.dispatch("auth/logout");
      this.$store.commit("survey/remove");
      window.location.href = `/login`
    },
    setTimeMessage(time) {
      const timeInterval = setInterval(() => {
        time--;
        this.updateMessage(time);
        if(time === 0) {
          clearInterval(timeInterval);
          this.logout();
        }
      }, 1000);
    },
    checkExpiration() {
      const user = LocalStoreService.getUser();
      if(user && user.expiration) {
        if(Date.now() > user.expiration) {
          this.expiration = true;
          this.updateMessage(Constants.TIME_BEFORE_SESSION_EXPIRED_LOGOUT);
          this.updateShowError(true);
          this.setTimeMessage(Constants.TIME_BEFORE_SESSION_EXPIRED_LOGOUT);
        }
      }
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.c-container {
  margin: 0px;
  padding: 0px;
}


@media (min-width: 1440px) {
  .c-body {
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media (max-width: 1439px) {
  .c-body {
    padding-left: 3%;
    padding-right: 3%;
  }
}

.c-main {
  padding-top: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
  transition: opacity .3s ease;
}
  
.modal-expire-confirm {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity .3s ease;
}

.custom-modal-dialog {
  margin: 0 auto !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-dialog {
  width: 1034px;
  height: 602px;
  background: white;
}

.custom-dialog-confirm {
  width: 467px;
  height: 283px;
  background: #4B4B4B;
}
  
.modal-custom-header {
  background: #E5F4FF;
  margin: 0px 0px 15px 0px;
  padding: 10px;
}

.modal-custom-body {
  background: white;
  margin: 0px;
  padding: 0px 25px;
}

.modal-custom-body-row {
  background: #f6f6f6;
  margin: 0px 25px;
  padding: 0px;
}

.modal-custom-product {
  background: white;
  margin: 0px 25px;
  min-height: 150px;
}

.body-text-modal{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: black;
}

.body-text-modal-confirm {
  width: 365px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  float: right;
}
.dis_checkbox {
  pointer-events: none;
}
.btn-modal-confirm {
  display: flex;
  justify-content: center;
}
</style>
